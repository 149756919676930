._9HetvA {
    z-index: 0;
}

.NqU4Yg {
    z-index: 1500000000;
    display: none;
}

._9HetvA, .NqU4Yg {
    position: relative;
}

.MxhaIQ._5aUrJg {
    opacity: 1;
}
.MxhaIQ.R4vkCQ {
    transition: opacity .25s ease-in-out;
}
.MxhaIQ {
    right: 0;
    left: 0;
    background-color: rgba(14,19,24,.45);
    position: fixed;
    overflow-y: auto;
    top: 0;
    bottom: 0;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.IywfPg {
    position: relative;
    margin: auto 0;
    padding: 16px 48px;
    box-sizing: border-box;
    max-width: 100%;
    display: flex;
    pointer-events: none;
}

.EYTKnA {
    pointer-events: auto;
    height: 100%;
    max-width: 100%;
}

.HmLo5g {
    position: relative;
}

.JFEjrQ {
    border-radius: 8px;
}
.Z2VWzw {
    background-color: #fff;
}
.OdX8fA {
    box-shadow: 0 0 0 1px rgba(14,19,24,.07), 0 2px 18px rgba(14,19,24,.25);
}
.JFEjrQ {
    min-width: 300px;
    position: relative;
}
.Z2VWzw {
    width: 100%;
    height: 100%;
}

.T_Xrlw {
    padding: 30px;
    max-width: 100%;
    box-sizing: border-box;
}

.gjqPYw {
    margin-bottom: 16px;
}

.bSncsg {
    margin: 0;
}
.l1bE7Q {
    color: #222;
}
.bSncsg {
    word-wrap: break-word;
    overflow-wrap: break-word;
}
.NAz2ZA {
    font-weight: 700;
    font-size: 1.3rem;
    letter-spacing: -.03rem;
    line-height: 1.3;
}

.jNAeLA {
    margin: 16px -8px 0;
}

.NT2yCg {
    background-color: transparent;
    border-color: rgba(14,19,24,.15);
}
.Qkd66A {
    border: 2px solid transparent;
    border-radius: 30px;
    cursor: pointer;
    margin: 0;
    padding: 0 6px;
}
._7x80Lg {
    flex: 0 0 auto;
    min-width: 180px;
}
.NT2yCg {
    color: rgba(14,19,24,.7);
}
.GnpDIA {
    justify-content: center;
}
.Qkd66A {
    color: #fff;
    display: inline-flex;
    height: 40px;
    letter-spacing: .01rem;
    line-height: 1.4;
    max-width: 100%;
    min-width: 80px;
    transition: background-color .1s linear,border-color .1s linear,color .1s linear;
    vertical-align: middle;
    align-items: center;
}

.n9zSJA {
    font-size: 1.3rem;
}

._8sh-Iw {
    background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
}

._7x80Lg {
    flex: 0 0 auto;
    min-width: 180px;
}

.jNAeLA {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
}

.o4TrkA {
    outline: none;
}

.MWlxIg, .n9zSJA {
    font-weight: 500;
    line-height: 1.6;
}
.n9zSJA {
    font-size: 0.9rem;
}
.NT2yCg {
    color: rgba(14,19,24,.7);
}

.yIDCqA, button {
    -webkit-tap-highlight-color: transparent;
}

.GnpDIA>._38oWvQ {
    margin: auto;
}
.Qkd66A>._38oWvQ {
    padding: 0 8px;
}
.Qkd66A>._38oWvQ {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
}

.NT2yCg {
    background-color: transparent;
    border-color: rgba(14,19,24,.15);
}

.NT2yCg.zQlusQ:hover {
    border-color: rgba(14,19,24,.45);
}

.i7NByQ {    
    order: 1;
    align-self: flex-start;
    position: -webkit-sticky;
    position: sticky;
    top: 25px;
    right: 55px;
    z-index: 10;
    position: absolute;
}

.CA2Rbg {
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
}
.T2s_Vg {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    pointer-events: auto;
    color: #7a818c;
    transition: background-color .25s ease-in-out;
}

.TcNIhA {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.f_b3kA {
    width: 24px;
    height: 24px;
}
.xR4dgg {
    display: inline-block;
    vertical-align: text-bottom;
    box-sizing: border-box;
}

.xR4dgg>svg {
    width: 100%;
    height: 100%;
    display: block;
}

._1OmKeA {
    border-radius: inherit;
}
._1OmKeA {
    max-width: 100%;
}

.Tor9eg {
    border-radius: inherit;
}

.Tor9eg {
    max-width: 100%;
}

.g8iqDA {
    text-align: left;
}

.g8iqDA {
    border-radius: inherit;
}
.g8iqDA {
    overflow: hidden;
}

.pRBKkQ {
    min-height: 240px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.f5ZG2A {
    font-size: 1.4rem;
    color: #fff;
    font-weight: 700;
}

.I_nTag {
    padding: 16px;
}

.u9XXAg:not(:last-child) {
    margin-bottom: 8px;
}
._jLCyQ {
    text-align: left;
}

.bSncsg {
    word-wrap: break-word;
    overflow-wrap: break-word;
    margin: 0;
}

.X8Afzw {
    display: none;
}

.NviU_g {
    margin: 0;
    padding: 0;
}

.vx8KSw {
    margin-left: 4px;
}
.vx8KSw {
    padding: 8px 0;
}
.sWSiTQ {
    display: flex;
    align-items: stretch;
}

._3h20OQ {
    flex: 0 0 auto;
}

.D9iACw {
    color: #6bca2c;
}
.f_b3kA {
    width: 24px;
    height: 24px;
}
.xR4dgg {
    display: inline-block;
    vertical-align: text-bottom;
    box-sizing: border-box;
}

.vx8KSw ._-mnO6g {
    margin-right: 8px;
}
._-mnO6g {
    margin-left: 8px;
    flex: 1;
}

._jLCyQ {
    text-align: left;
}

.yPRhMg {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.Qkd66A {
    border: 2px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    padding: 0 6px;
}
._7WRzmA {
    flex: 1 1 auto;
}

.zKTE_w {
    background-color: #00c4cc;
}

._7WRzmA {
    flex: 1 1 auto;
}
.GnpDIA {
    justify-content: center;
}
._bDL5A {
    padding-top: 12px;
}

.HnhAQA {
    text-align: center;
}

.MWlxIg {
    font-size: 1.2rem;
}

._5JgSIQ a, ._6pE-dQ a, .eNPKgw, .oCRJNQ, .oUJMcQ, .ovm4pQ, .raaLGQ a, .T26_6Q, .wL6vqQ a, .z2oZFw a {
    cursor: pointer;
    text-decoration: underline;
}

.ovm4pQ, .T26_6Q, .wL6vqQ a, .z2oZFw a {
    color: #00c4cc;
}

.NT2yCg {
    color: rgba(14,19,24,.7);
}

.NT2yCg.Fdttyw, .NT2yCg.zQlusQ.Fdttyw, .NT2yCg.zQlusQ:active, .NT2yCg:active {
    background-color: rgba(14,19,24,.15);
    border-color: rgba(14,19,24,.7);
}
.NT2yCg.zQlusQ:hover {
    border-color: rgba(14,19,24,.45);
}
.NT2yCg {
    background-color: transparent;
    border-color: rgba(14,19,24,.15);
}

.zKTE_w.zQlusQ:hover {
    background-color: #00d9e1;
}

.vZfg_Q {
    min-width: 16px;
    min-height: 16px;
}

._7x80Lg {
    margin: 8px;
}


@media (min-width: 560px) {
    .Tor9eg {
        width: 560px;
    }
    .pRBKkQ {
        text-align: left;
        padding-right: 50%;
    }
    .pRBKkQ {
        min-height: 240px;
        box-sizing: border-box;
    }
    .pRBKkQ {
        background-size: cover;
        background-position: 50% 50%;
    }
    .zYxu0Q {
        padding: 0 32px;
    }

    .I_nTag {
        padding: 24px 32px 32px;
    }

    .X8Afzw {
        display: block;
    }

    .yPRhMg {
        margin-top: 16px;
    }

    .yPRhMg {
        flex-direction: row;
    }
}


@media (max-width: 991px) {
    .i7NByQ {    
        right: 25px;
        z-index: 10;
        position: absolute;
    }
    .IywfPg {
        padding: 16px;
    }
}



@media (min-width: 992px) {
    .T_Xrlw {
        min-width: 500px;
        max-width: 750px;
    }
}



